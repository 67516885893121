import React from 'react'

export const Contact = props => {
    let { name, nameFilled, message, messageFilled, email, emailFilled, validateInputs, resetFields, handleChange, close, error } = props;
    return (
        <React.Fragment>
        <h2 className="major">Contact</h2>
        <div className="field half first">
          <label htmlFor="name">Name</label>
          <input value={name} onChange={(e) => handleChange("name", e.target.value)} type="text" id="name" />
        </div>
        {nameFilled ? <br /> : <p id="alert"> Please fill out the name field. </p>}
        <div className="field half">
          <label htmlFor="email">Email</label>
          <input value={email} onChange={(e) => handleChange("email", e.target.value)} type="text" name="email" id="email" />
        </div>
        {emailFilled ? <br /> : <p id="alert"> Please fill out the email field. </p>}
        <div className="field">
          <label htmlFor="message">Message</label>
          <textarea value={message} maxLength={500} onChange={(e) => handleChange("message", e.target.value)} name="message" id="message" rows="4"></textarea>
          <p id="subtitle"> {message.length} / 500 Characters</p>
          {messageFilled ? <br /> : <p id="alert"> Please fill out the message field. </p>}
        </div>
        <ul className="actions">
          <li><button onClick={validateInputs}>Send Message</button></li>
          <li><input onClick={resetFields} type="reset" value="Reset" /></li>
        </ul>
        <ul className="icons">
          {error ? <p id="alert"> Oh no! Something went awry! <br /> Was your email formatted correctly? Please try again or reach out to  <a href="mailto:jay@sargentassociates.com">jay@sargentassociates.com</a> directly. Thank you!</p> : ''}
          <li><a href="https://www.linkedin.com/company/sargentassociates/" className="icon fa-linkedin"><span className="label">Linked In</span></a></li>
          <li><a href="https://www.facebook.com/sargentassoc1/" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
          {/* <p id="subtitle"><br/></p> */}
        </ul>
        {close}
      </React.Fragment>
    )
}