import React from 'react'
import { _websites } from '../../assets/data/website_items'
import { WebsitePortfolioItem } from './WebsitePortfolioItem/WebsitePortfolioItem';
import { returnImageArray } from '../../assets/data/fns'
import './_website.scss'

export class WebsitePortfolio extends React.Component {

    render() {
        let portfolioArray = returnImageArray(_websites, "all");
        let mapped = portfolioArray.map(item => {
            return <WebsitePortfolioItem key={item.url} url={item.url} />
        })
        return (
            <div>
                {mapped}
            </div>
        )
    }
}