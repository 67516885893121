import React from 'react'

export const WebsitePortfolioItem = props => {
    let { url, title, address } = props;
    return (
        <div className="website">
            {title}
            <img width="100%" src={url} alt="website example"/>
            {address}
        </div>
    )
}