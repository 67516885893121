import React from 'react'

export const Success = props => {
    let { close, resetFields } = props;
    let [timerID, setTimerID] = React.useState(null);
    React.useEffect(() => {
        setTimerID(setTimeout(resetFields, 4000));
    }, [])

    return (
        <React.Fragment>
                <p>Your message has been sent.</p>
            <div onClick={() => clearTimeout(timerID)}>
                {close}
            </div>
        </React.Fragment>
    )
}